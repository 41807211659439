import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from 'gatsby';

import SEO from "../../components/seo";
import ProjectLayout from "../../components/layouts/projectLayout";
import Banner from "../../components/project/banner";
import ProjectSection from "../../components/project/projectSection";
import ProjectFooter from "../../components/project/projectFooter";
import ScreenshotsContainer from "../../components/project/screenshotsContainer";
import {ProjectSectionTitle, ProjectSectionsubtitle, ProjectSectionDescription, ProjectSectionList, ProjectSectionListItem} from "../../components/project/projectTypography";
import {ScreenshotsBefore, ScreenshotsAfter, InvestigateIssues, CustomerJourney, BuildFoundation, AdminNavigation, AdminList, AdminDashboard, UserPortal, UserPortalMobileApp, UserProtect} from "../../components/img/oneloginImages";


import Colors from "../../constants/colors";
import Fonts from "../../constants/fonts";

// Images
import BannerImage from "../../images/project_banners/Project_banner-OneLogin.png";

const getProject = graphql`
  query {
    project:allContentfulProject(filter: {title:{eq: "OneLogin"}}) {
      edges {
        node {
          title
          description {
            description
          }
          slug
          order
          year
          role
          contentful_id
        }
      }
    }
  }
`;

const AdminSideList = styled.ul`
  margin: 32px 0 0 29px;
  list-style: none;

  li {
    margin-bottom: 24px;
    position: relative;
    
    &::after {
      display: block;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      position: absolute;
      left: -29px; 
      top: 0;
      font-size: 0.8125rem;
      line-height: 21px;
      font-family: ${Fonts.Font_family.sans_serif};
      font-weight: bold;
      color: ${Colors.Basic.white};
    }

    &.list-a {
      &::after {
        content: "A";
        padding-left: 6px;
        background-color: #F28D00;
      }
    }
    &.list-b {
      &::after {
        content: "B";
        padding-left: 7px;
        background-color: #4CC1D9;
      }
    }
    &.list-c {
      &::after {
        content: "C";
        padding-left: 6px;
        background-color: #F26DA4;
      }
    }
    &.list-d {
      &::after {
        content: "D";
        padding-left: 7px;
        background-color: #62BF43;
      }
    }
  }
  h5 {
    font-family: ${Fonts.Font_family.sans_serif};
    font-size: 0.875rem;
    line-height: 21px;
    margin-bottom: 5px;
  }
  p {
    font-size: 0.8125rem;
    line-height: 21px;
    margin-bottom: 0;
  }
`;

const OneLogin = () => {
  const data = useStaticQuery(getProject);
  const project = data.project.edges[0].node;
  const sections = [
    {
      "name": "Goals",
      "to": "goals"
    },
    {
      "name": "Find Issues",
      "to": "find-issues"
    },
    {
      "name": "Build Foundation",
      "to": "build-foundation"
    },
    {
      "name": "Admin Experience",
      "to": "admin-experience"
    },
    {
      "name": "End User Experience",
      "to": "end-user-experience"
    }
  ]

  return (  
    <ProjectLayout projectTitle={project.title} sections={sections}>
      <SEO title="Project - OneLogin" />
      <Banner
        bgColor="#EDF1F2"
        bgImage={BannerImage}
        title={project.title}
        description={project.description.description}
        year={project.year}
        role={project.role}
      />

      <ProjectSection paddingBottom="0" name={sections[0].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[0].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Modernize outdated user experience
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description">
            <ProjectSectionDescription>
              The original UI/UX missed some essential features and was inconsistent, to it hindered affordances and accessibility. Our goal is to build a user-friendly experience with a comprehensive design system.  
            </ProjectSectionDescription>
          </div>
        </div>
      </ProjectSection>
      <ProjectSection bgColor="#F2F2F2" paddingTop="48px" paddingBottom="0">
        <ScreenshotsContainer titleOffsetY="32px" titleFontWeight="normal" infoWidth="10%">
          <div className="info">
            <h4>Before</h4>
          </div>
          <ScreenshotsBefore></ScreenshotsBefore>
        </ScreenshotsContainer>
      </ProjectSection>
      <ProjectSection bgColor="#3B4045" paddingTop="48px" paddingBottom="0">
        <ScreenshotsContainer titleOffsetY="32px" titleFontWeight="normal" infoWidth="10%" titleColor={Colors.Basic.white} underlineColor={Colors.Underline.pink}>
          <div className="info">
            <h4>After</h4>
          </div>
          <ScreenshotsAfter></ScreenshotsAfter>
        </ScreenshotsContainer>
      </ProjectSection>

      <ProjectSection description="false" name={sections[1].to} paddingBottom="0">
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[1].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Discover the problems and define objectives
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description">
            <ProjectSectionDescription>
              Scrutinize all UI/UX of products and analyze what is not working. Our team also creates a customer journey which includes interactions with different departments. And we collect user feedback to support the decisions.
            </ProjectSectionDescription>
          </div>
        </div>
      </ProjectSection>
      <ProjectSection bgColor="#FAFAFA" paddingTop="40px" paddingBottom="40px">
        <ScreenshotsContainer titleOffsetY="32px" titleFontWeight="normal">
          <div className="info">
            <h4>Investigate UX Issues</h4>
          </div>
          <InvestigateIssues></InvestigateIssues>
        </ScreenshotsContainer>
      </ProjectSection>
      <ProjectSection bgColor="#3B4045" paddingTop="40px" paddingBottom="40px">
        <ScreenshotsContainer column titleOffsetY="0" titleFontWeight="normal" titleColor={Colors.Basic.white} underlineColor={Colors.Underline.pink}>
          <div className="info">
            <h4>Customer Journey</h4>
          </div>
          <CustomerJourney></CustomerJourney>
        </ScreenshotsContainer>
      </ProjectSection>

      <ProjectSection bgColor="#FAFAFA" name={sections[2].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[2].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Build consistent standards and guidelines
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description section-content__description--list">
            <ProjectSectionDescription>
              Before I joined, we didn't have a design system, so my top priority to redesign the new UI and build a design system simultaneously. 
            </ProjectSectionDescription>
            <ProjectSectionList>
              <ProjectSectionListItem>Foundations — colors, typogrophy and grid system</ProjectSectionListItem>
              <ProjectSectionListItem>Components and layout patterns</ProjectSectionListItem>
              <ProjectSectionListItem>React.js component library</ProjectSectionListItem>
            </ProjectSectionList>
          </div>
        </div>
        <BuildFoundation></BuildFoundation>
      </ProjectSection>

      <ProjectSection name={sections[3].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle>{sections[3].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle>
              Clarify the functions and task flows for data management
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description">
            <ProjectSectionDescription>
              The original UI lack of clear indications and task flows, so users sometimes feel overwhelmed and lost. We break down the pain points and rebuild the information hierarchy and user interface.
            </ProjectSectionDescription>
          </div>
        </div>
        <ScreenshotsContainer marginTop="24px" marginBottom="120px" infoWidth="26%">
          <div className="info">
            <h4>Navigation</h4>
            <AdminSideList>
              <li className="list-a">
                <h5>Main Navigation</h5>
                <p>Add blue highlight indicator to mark current section.</p>
              </li>
              <li className="list-b">
                <h5>Title / Toolbar</h5>
                <p>Use breadcrumbs to show location and centralize the global functions.</p>
              </li>
              <li className="list-c">
                <h5>Secondary Navigation</h5>
                <p>Reorganize and separate the content with clearer side navigation.</p>
              </li>
            </AdminSideList>
          </div>
          <AdminNavigation></AdminNavigation>
        </ScreenshotsContainer>
        <ScreenshotsContainer marginBottom="120px" underlineColor={Colors.Underline.pink} infoWidth="26%">
          <div className="info">
            <h4>List / Table</h4>
            <AdminSideList>
              <li className="list-a">
                <h5>Search / Toolbar</h5>
                <p>Put search function for all lists.</p>
              </li>
              <li className="list-b">
                <h5>Filters</h5>
                <p>Redesign filters to easier to find results.</p>
              </li>
              <li className="list-c">
                <h5>Data Rows</h5>
                <p>Implement sorting, partially section and dropdown menu of each row.</p>
              </li>
              <li className="list-d">
                <h5>Pagination</h5>
                <p>Refine pagination with “Go to the  page” / “Rows per page” features.</p>
              </li>
            </AdminSideList>
          </div>
          <AdminList></AdminList>
        </ScreenshotsContainer>
        <ScreenshotsContainer marginBottom="120px" underlineColor={Colors.Underline.green} infoWidth="26%">
          <div className="info">
            <h4>Dashboard / Data Visualization</h4>
            <p>Collect deals from services and organize them into categories, which are easily navigated.</p>
          </div>
          <AdminDashboard></AdminDashboard>
        </ScreenshotsContainer>
      </ProjectSection>


      <ProjectSection bgColor="#3B4045" name={sections[4].to}>
        <div className="section-content__header">
          <div className="section-content__title">
            <ProjectSectionTitle color={Colors.Basic.white}>{sections[4].name}</ProjectSectionTitle>
            <ProjectSectionsubtitle color={Colors.Basic.white}>
              Keep the experience simple and intuitive
            </ProjectSectionsubtitle>
          </div>
          <div className="section-content__description">
            <ProjectSectionDescription color={Colors.Basic.white}>
              For an app used by a variety of different people every day, our priority is to reduce the unnecessary visual burden and to focus on keeping the task flow as simple as possible.
            </ProjectSectionDescription>
          </div>
        </div>
        <ScreenshotsContainer titleColor={Colors.Basic.white} titleOffsetY="32px" infoWidth="20%">
          <div className="info">
            <h4>Portal</h4>
            <p>Access all the company and personal apps with keyboard-friendly controls.</p>
          </div>
          <UserPortal></UserPortal>
        </ScreenshotsContainer>
        <ScreenshotsContainer titleColor={Colors.Basic.white} titleOffsetY="32px" infoWidth="20%" underlineColor={Colors.Underline.pink}>
          <div className="info">
            <h4>Portal Mobile App</h4>
            <p>The mobile portal which is redesigned for an "on-the-go" experience.</p>
          </div>
          <UserPortalMobileApp></UserPortalMobileApp>
        </ScreenshotsContainer>
        <ScreenshotsContainer titleColor={Colors.Basic.white} titleOffsetY="32px" infoWidth="20%" underlineColor={Colors.Underline.green}>
          <div className="info">
            <h4>Protect</h4>
            <p>An authentication app is designed for extra security and a clean notification system.</p>
          </div>
          <UserProtect></UserProtect>
        </ScreenshotsContainer>
      </ProjectSection>

      <ProjectFooter currentProjectOrder="2">
      </ProjectFooter>
    </ProjectLayout>
  )
};

export default OneLogin;
