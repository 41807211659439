import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from 'styled-components';

import Colors from '../../constants/colors';
import Breakpoints from "../../constants/breakpoints";

const getData = graphql`
  query {
    goalsBefore: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/OneLogin/comparison/before" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    goalsAfter: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/OneLogin/comparison/after" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    investigateIssues: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/OneLogin/find_issues/investigate_ux_issues" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    customerJourney: file(relativePath: { eq: "images/projects/OneLogin/find_issues/customer_journey/customer_journey.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    buildFoundation: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/OneLogin/build_foundation" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    adminNavigation: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/OneLogin/admin_experience/navigation" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    adminList: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/OneLogin/admin_experience/list" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    adminDashboard: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/OneLogin/admin_experience/dashboard" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    userPortal: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/OneLogin/end_user_experience/portal" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    userPortalMobileApp: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/OneLogin/end_user_experience/portal_mobile_app" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    userProtect: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "images/projects/OneLogin/end_user_experience/protect" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

function withImageData(WrappedComponent) {
  return props => (
    <StaticQuery
      query={getData}
      render={data => <WrappedComponent {...props} imageData={data} />}
    />
  );
}

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
    flex-direction: column;
  }
`;

const GoalsScreenshots = styled(ImageContainer)`
  > div {
    width: 31%;
    max-width: 400px;

    @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
      width: 32%;
    }
  
    @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
      width: 100%;
      max-width: 400px;
    }

    &:not(:last-child) {
      margin-right: 4.5%;

      @media only screen and (max-width: ${Breakpoints.Width.tablet_small}) {
        margin-right: 2%;
      }

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }

    img {
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
    }
  }
`;

const InvestigateIssuesScreenshots = styled(ImageContainer)`
  > div {

    &:first-child {
      width: 58%;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        width: 100%;
      }
    }
    &:last-child {
      width: 38%;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        width: 100%;
      }
    }

    &:not(:last-child) {
      margin-right: 4%;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
`;

const BuildFoundationScreenshots = styled(ImageContainer)`
  > div {
    &:nth-child(1) {
      width: 29.4208%;
      max-width: 386px;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        width: 100%;
        max-width: 100%;
      }
    }

    &:nth-child(2) {
      width: 29.4208%;
      max-width: 386px;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        width: 100%;
        max-width: 100%;
      }
    }

    &:nth-child(3) {
      width: 32.0122%;
      max-width: 420px;

      img {
        border: 1px solid ${Colors.Basic.gray_95};
      }

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        width: 100%;
        max-width: 100%;
      }
    }

    &:not(:last-child) {
      margin-right: 4.5731%;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
`;

const AdminScreenshots = styled(ImageContainer)`
  > div {
    &:nth-child(1) {
      width: 47.9166%;
      max-width: 460px;
      
      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        width: 100%;
        max-width: 100%;
      }
    }

    &:nth-child(2) {
      width: 47.9166%;
      max-width: 460px;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        width: 100%;
        max-width: 100%;
      }
    }

    &:not(:last-child) {
      margin-right: 4.1666%;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
`;

const AdminScreenshotsDashboard = styled(AdminScreenshots)`
  > div {
    &:nth-child(1) {
      img {
        border: 1px solid ${Colors.Basic.gray_95};
      }
    }
  }
`;

const UserScreenshotsPortal = styled(ImageContainer)`
  > div {
    width: 47.9166%;
    max-width: 460px;

    @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
      width: 100%;
      max-width: 100%;
    }

    &:not(:last-child) {
      margin-right: 4.1666%;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
`;

const UserScreenshotsMobile = styled(ImageContainer)`
  > div {
    width: 21.875%;
    max-width: 210px;

    @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
      width: 100%;
      max-width: 100%;
    }

    &:not(:last-child) {
      margin-right: 4.1666%;

      @media only screen and (max-width: ${Breakpoints.Width.mobile_large}) {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
`;


export const ScreenshotsBefore = withImageData(props => (
  <GoalsScreenshots>
    {props.imageData.goalsBefore.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
          imgStyle={{objectFit:'contain',objectPosition: '0 0'}}
          objectPosition="0 0"
          objectFit="contain"
        />
    ))}
  </GoalsScreenshots>
));

export const ScreenshotsAfter = withImageData(props => (
  <GoalsScreenshots>
    {props.imageData.goalsAfter.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
          imgStyle={{objectFit:'contain',objectPosition: '0 0'}}
          objectPosition="0 0"
          objectFit="contain"
        />
    ))}
  </GoalsScreenshots>
));

export const InvestigateIssues = withImageData(props => (
  <InvestigateIssuesScreenshots>
    {props.imageData.investigateIssues.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
          imgStyle={{objectFit:'contain'}}
          objectFit="contain"
        />
    ))}
  </InvestigateIssuesScreenshots>
));

export const CustomerJourney = withImageData(props => (
  <Img fluid={props.imageData.customerJourney.childImageSharp.fluid} alt="Customer Journey" />
));

export const BuildFoundation = withImageData(props => (
  <BuildFoundationScreenshots data-sal="slide-up" data-sal-delay="600" data-sal-duration="800">
    {props.imageData.buildFoundation.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
          imgStyle={{objectFit:'contain'}}
          objectFit="contain"
        />
    ))}
  </BuildFoundationScreenshots>
));


export const AdminNavigation = withImageData(props => (
  <AdminScreenshots>
    {props.imageData.adminNavigation.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
          imgStyle={{objectFit:'contain'}}
          objectFit="contain"
        />
    ))}
  </AdminScreenshots>
));


export const AdminList = withImageData(props => (
  <AdminScreenshots>
    {props.imageData.adminList.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
          imgStyle={{objectFit:'contain'}}
          objectFit="contain"
        />
    ))}
  </AdminScreenshots>
));


export const AdminDashboard = withImageData(props => (
  <AdminScreenshotsDashboard>
    {props.imageData.adminDashboard.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
          imgStyle={{objectFit:'contain'}}
          objectFit="contain"
        />
    ))}
  </AdminScreenshotsDashboard>
));


export const UserPortal = withImageData(props => (
  <UserScreenshotsPortal>
    {props.imageData.userPortal.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
          imgStyle={{objectFit:'contain'}}
          objectFit="contain"
        />
    ))}
  </UserScreenshotsPortal>
));

export const UserPortalMobileApp = withImageData(props => (
  <UserScreenshotsMobile>
    {props.imageData.userPortalMobileApp.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
          imgStyle={{objectFit:'contain'}}
          objectFit="contain"
        />
    ))}
  </UserScreenshotsMobile>
));


export const UserProtect = withImageData(props => (
  <UserScreenshotsMobile>
    {props.imageData.userProtect.edges
      .sort((a, b) => a.node.base.split("-")[0]-b.node.base.split("-")[0])
      .map((image, index) => (
        <Img
          key={index}
          fluid={image.node.childImageSharp.fluid}
          alt={image.node.base.split("-")[1].split(".")[0]}
          imgStyle={{objectFit:'contain'}}
          objectFit="contain"
        />
    ))}
  </UserScreenshotsMobile>
));
